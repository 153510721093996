import {Component} from '@angular/core';

import {AbstractComponent} from '../../core/components/abstract.component';
import {SITE_MENU} from './menu';
import {RoleKey} from '../../core/services/auth.service';
import {MenuItem} from './menu.model';

/**
 * Nav (Top) Menu Component
 */
@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styles: [],
})
export class TopMenuComponent extends AbstractComponent {

  public show = false;
  menuItems: MenuItem[] = [];

  override ngOnInit(): void {
    super.ngOnInit();
    this.menuItems = SITE_MENU;
  }

  userInfo() {
    this.global.navigate(['/account', 'info']);
  }

  displayMenuItem(item: any) {
    const roles = item.roles;
    const result = Object.keys(this.roles).find(role => {
      return this.roles[role as RoleKey] && (!roles.hasOwnProperty(role) || roles[role as RoleKey] === true)
    });
    return !!result;
  }

  menuClick(event: any, item: any) {
    if (item?.modal && item.modal === 'signup') {
      this.global.auth.signup(item);
    }
    if (item?.id && item?.message) {
      this.global.notification.seen(item);
      if (item?.url) {
        this.global.navigate([item.url]);
      }
    }
    this.global.onMenuClick(event);
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
