import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

import {AbstractService} from './abstract.service';
import {apiUrls} from '../config';
import {HttpService, Response} from './http.service';
import {ToastService} from './toast.service';
import {ToolsService} from './tools.service';

@Injectable()
export class ChatService extends AbstractService {

  override useCache = false;
  override endpoint = apiUrls.chat;
  override name = 'chat';
  private _booking?: any; // Active booking for chat
  public show = false;
  public openChat$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public activeGuid?: string; // Currently opened chat GUID
  public fetching = false;

  constructor(override http: HttpService,
              private toast: ToastService,
              private tools: ToolsService) {
    super(http);
  }

  get booking(): any {
    return this._booking;
  }

  set booking(value: any) {
    this._booking = value;
    this.show = !!value;
  }

  loadMessages() {
    // return messages;
  }

  deleteMessages$(params: Params = {}): Observable<Response> {
    return this.http.post(this.endpoint.messages.delete, params, () => null);
  }

  deleteMessages(params: Params = {}) {
    this.deleteMessages$(params).subscribe(response => {
      if (response?.data) {
        let messages = [];
        if (response.data?.succeeded > 0) {
          messages.push('Removed ' + this.tools.plural('message', response.data.succeeded));
          this.listingUpdated$.next(true);
        }
        if (response.data?.failed > 0) {
          messages.push('Failed ' + this.tools.plural('message', response.data.failed) + '::');
          if (Array.isArray(response.data?.failedList)) {
            response.data.failedList.forEach((id: string) => {
              messages.push(id);
            });
          }
        }

        this.toast.show(messages.join('::'), {
          title: response?.success === true ? 'Done' : 'Sorry',
          icon: 'switch-horizontal',
          status: response?.success === true ? 'success' : 'danger',
        });
      }
    });
  }

  loadBotReplies() {
    // return botReplies;
  }

  reply(message: string) {
    // const botReply: any =  this.loadBotReplies()
    // .find((reply: any) => message.search(reply.regExp) !== -1);
    //
    // if (botReply.reply.type === 'quote') {
    //   botReply.reply.quote = message;
    // }
    //
    // if (botReply.type === 'gif') {
    //   botReply.reply.files[0].url = gifsLinks[Math.floor(Math.random() * gifsLinks.length)];
    // }
    //
    // if (botReply.type === 'pic') {
    //   botReply.reply.files[0].url = imageLinks[Math.floor(Math.random() * imageLinks.length)];
    // }
    //
    // if (botReply.type === 'group') {
    //   botReply.reply.files[1].url = gifsLinks[Math.floor(Math.random() * gifsLinks.length)];
    //   botReply.reply.files[2].url = imageLinks[Math.floor(Math.random() * imageLinks.length)];
    // }
    //
    // botReply.reply.text = botReply.answerArray[Math.floor(Math.random() * botReply.answerArray.length)];
    // return { ...botReply.reply };
  }
}
