import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

import {environment} from '../environments/environment';
import {AuthService, UserIdentity} from './core/services/auth.service';
import {SeoService} from './core/services/seo.service';
import {AppData} from './core/appdata';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = environment.title;

  constructor(private cookie: CookieService, private auth: AuthService, private seo: SeoService, private appData: AppData) {}

  onActivate(event: Event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit(): void {
    const passthroughCookie = this.cookie.get('x-pt');
    if (passthroughCookie) {
      this.auth.isPassthrough = true;
      // console.log(JSON.parse(atob(passthroughCookie)));
      this.auth.passthrough({value: passthroughCookie}).subscribe({
        next: (user: any) => {
          this.auth.identity = user as UserIdentity;
        },
        error: (error: any) => {
          console.log('Passthrough', error);
          this.auth.isPassthrough = false;
        }
      });
    }

    this.seo.bbPages = this.appData.bbPages ?? {};

    this.auth.status().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        console.log('Good to go');
      }
    });
  }
}
