import {
  Injectable,
} from '@angular/core';
import {KeyValue, Location} from '@angular/common';
import {Router, NavigationEnd, RoutesRecognized} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {filter} from 'rxjs/operators';
import {BehaviorSubject, merge, pairwise} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

import {MiddleTruncatePipe} from '../pipes/mid-truncate.pipe';
import {BoatService} from './boat.service';
import {ContentService} from './content.service';
import {AuthService} from './auth.service';
import {ToolsService} from './tools.service';
import {ToastService} from './toast.service';
import {ApiService} from './api.service';
import {OptionService} from './option.service';
import {MarinaService} from './marina.service';
import {BookingService} from './booking.service';
import {NotificationService} from './notification.service';
import {ChatService} from './chat.service';
// import {JsonLDService} from './json-ld.service';
import {SeoService} from './seo.service';
import {VisibilityService} from './visibility.service';
import {ComponentBlock, LazyService} from './lazy.service';
import {ValidatorService} from './validator.service';
import {BufferService} from './buffer.service';
import {CountryService} from './country.service';
import {GtagService} from './gtag.service';

export type MediaQuery = {
  rule: string;
  matched: boolean;
  mql?: MediaQueryList,
  name: string
}

/**
 * Providing a singleton service
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public currentRoute = '';
  public checkValidity = false;
  public options: any[] = [];
  public previousUrl: string | undefined;
  public currentUrl: string | undefined;
  public wideHeader = false;
  public isBackend = false;
  public weekDays = {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
  };
  // Note: don't forget to update tools.isMobile()
  public media: MediaQuery[] = [
    // Less than 768px
    {rule: '(max-width: 768px)', matched: false, name: 'lt.768'},
    // Greater than 768px
    {rule: '(min-width: 768px)', matched: false, name: 'gt.768'},
    // Less than 992px
    {rule: '(max-width: 992px)', matched: false, name: 'lt.992'},
    // Greater than 1280px
    {rule: '(min-width: 1280px)', matched: false, name: 'gt.1280'}
  ];
  // Occurs when NavigationEnd event is caught
  public navigationEnded$ = new BehaviorSubject<boolean>(false);
  public mediaEvent$ = new BehaviorSubject<any>({name: '', value: false});
  public datepickerToggle$ = new BehaviorSubject<{block?: ComponentBlock, value: boolean}>({value: false});

  constructor(public boat: BoatService,
              public booking: BookingService,
              public content: ContentService,
              public api: ApiService,
              public option: OptionService,
              public marina: MarinaService,
              public modal: NgbModal,
              public location: Location,
              public auth: AuthService,
              public tools: ToolsService,
              public toast: ToastService,
              public formBuilder: FormBuilder,
              public cookie: CookieService,
              public buffer: BufferService,
              public chat: ChatService,
              public country: CountryService,
              public notification: NotificationService,
              public midTruncate: MiddleTruncatePipe,
              public gtag: GtagService,
              // public jsonLD: JsonLDService,
              public seo: SeoService,
              public visibility: VisibilityService,
              public lazy: LazyService,
              public validator: ValidatorService,
              private router: Router) {
    this.media.forEach(item => {
      item.mql = window.matchMedia(item.rule);
      item.mql.addEventListener('change', (event) => {
        item.matched = event.matches;
        this.mediaEvent$.next({name: item.name, value: item.matched});
      });
      this.mediaEvent$.next({name: item.name, value: item.mql.matches});
    });

    router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.currentRoute = route.url;
        if (this.currentRoute && this.currentRoute.length > 0) {
          this.currentRoute = this.currentRoute.slice(1);
        }
      }
    });

    merge(
      this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise()),
      this.router.events.pipe(filter((evt: any) => evt instanceof NavigationEnd))
    ).pipe().subscribe((events: RoutesRecognized[] | NavigationEnd) => {
      if (events instanceof NavigationEnd) {
        this.wideHeader = events.url.startsWith('/search');
        this.isBackend = events.url.startsWith('/account');
        this.navigationEnded$.next(true);
      } else {
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
      }
    });
  }

  /**
   * Open scroll modal
   * @param staticDataModal
   */
  toggleModal(staticDataModal: any) {
    this.modal.open(staticDataModal, {size: 'lg', centered: true});
  }

  navigate(commands: any[], extras?: any) {
    this.router.navigate(commands, extras);
  }

  addBoat() {
    this.navigate(['/account', 'boat', 'add']);
  }

  /**
   * On menu click
   */
  onMenuClick(event: any) {
    const nextEl = event.target.nextElementSibling;
    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove('show');
      }
      nextEl.classList.toggle('show');
    }
    return false;
  }

  /**
   * On mobile toggle button clicked
   */
  toggleMobileMenu() {
    if (window.screen.width <= 1024) {
      document.getElementById('navbarNav')?.classList.toggle('show');
    }
  }

  closeMobileMenu() {
    document.getElementById('navbarNav')?.classList.remove('show');
  }

  /**
   * On mobile toggle button clicked
   */
  SideBarMenu() {
    document.getElementById('account-nav')?.classList.toggle('show');
  }

  /**
   * Filter button clicked
   */
  SidebarShow(id = 'main-sidebar') {
    document.getElementById(id)?.classList.toggle('show');
    document.querySelector('.vertical-overlay')?.classList.toggle('show');
  }

  /**
   * SidebarHide modal
   */
  SidebarHide(id = 'main-sidebar') {
    document.getElementById(id)?.classList.remove('show');
    document.querySelector('.vertical-overlay')?.classList.remove('show');
  }

  // Preserve original property order (for KeyValue pipe)
  public originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  public asIsOrder = () => 0;
}
