import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {apiUrls} from '../config';
import {Params} from '@angular/router';
import {AbstractService} from './abstract.service';

@Injectable()
export class ApiService extends AbstractService {

  // override endpoint = apiUrls.api.provider;

  providers$(params: Params = {}) {
    params = Object.assign(params, {
      endpoint: apiUrls.api.provider.list
    })
    return this.list$(params);
  }

  endpoints$(params: Params = {}) {
    params = Object.assign(params, {
      endpoint: apiUrls.api.endpoint.list
    })
    return this.list$(params);
  }

  currencies$() {
    return this.http.list(apiUrls.currency.list);
  }

  countries() {
    return this.http.list(apiUrls.country.list);
  }

  cancellationPolicies$() {
    return this.http.list(apiUrls.cancellationPolicy.list);
  }

  paymentMethods$() {
    return this.http.list(apiUrls.paymentMethod.list);
  }

  getFileData$(url: string | null = null) {
    if (url) {
      return this.http.blob(url);
    }
    return of({});
  }
}
