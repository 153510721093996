import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
// These modules increase bundle size, so for lazy loading use provideAnimations() in specific module Providers section
// import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';

import {HttpService} from './core/services/http.service';
import {BoatService} from './core/services/boat.service';
import {ContentService} from './core/services/content.service';
import {ApiService} from './core/services/api.service';
import {OptionService} from './core/services/option.service';
import {GlobalService} from './core/services/global.service';
import {ToolsService} from './core/services/tools.service';
import {AuthInterceptor} from './core/services/auth.interceptor';
import {MarinaService} from './core/services/marina.service';
import {BookingService} from './core/services/booking.service';
import {BufferService} from './core/services/buffer.service';
import {ChatService} from './core/services/chat.service';
import {CountryService} from './core/services/country.service';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {AppData} from './core/appdata';
import {MiddleTruncatePipe} from './core/pipes/mid-truncate.pipe';
import {WindowRefService} from './core/services/window-ref.service';
import {GtagService} from './core/services/gtag.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [
    MiddleTruncatePipe,
    CookieService,
    HttpService,
    WindowRefService,
    GtagService,
    BoatService,
    BookingService,
    ContentService,
    MarinaService,
    ApiService,
    OptionService,
    GlobalService,
    BufferService,
    ChatService,
    CountryService,
    ToolsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    AppData,
    {
      provide: AppData,
      useValue: window?.['APP_DATA' as unknown as number]
    }
  ],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule {
}
