export const BASE_PATH = '';

export const constant = {
  IMAGE_PATH: BASE_PATH + 'assets',
  SIGNIN_UNDEFINED: 0,
  SIGNIN_WITH_PASSWORD: 10,
  SIGNIN_WITH_OTP: 20,
  PHONE_PATTERN: '^\\+?[-()0-9]{5,}$',
  DEFAULT_MARINA: 'Dubai - All Marinas',
  DEFAULT_HEADER_1: 'Find the best boat rentals &',
  DEFAULT_HEADER_2: 'Yacht charters in Dubai',
  PUSHER_APP_KEY: 'renCHkT9fjnFfctc',
  PUSHER_WS_PORT: 80,
  PUSHER_WSS_PORT: 443,
  PUSHER_SCHEME: 'http',
  CHANNEL_NAME: 'book.boats',
};

const apiPrefix = '/api';
const websocketPrefix = '/ws';

/**
 * When adding items to layout don't forget to add .order-X style, because bootstrap covers only order-1..order-5
 */
export const defaultLayout = [
  {id: 'date', name: 'Date & estimated reading', icon: 'fi-calendar', order: 0, visible: true},
  {id: 'image', name: 'Main image', icon: 'fi-image', order: 1, visible: true},
  {id: 'social', name: 'Social buttons', icon: 'fi-facebook', order: 2, visible: true},
  {id: 'short', name: 'Short content', icon: 'fi-file', order: 3, visible: true},
  {id: 'long', name: 'Long content', icon: 'fi-file', order: 4, visible: true},
  {id: 'gallery', name: 'Image gallery', icon: 'fi-image', order: 5, visible: true},
  {id: 'contact', name: 'Contact form', icon: 'fi-info-square', order: 6, visible: false},
  {id: 'boats', name: 'Random boats', icon: 'fi-anchor', order: 7, visible: false},
];

export type PageLayout = {
  date: {id: string, name: string, order: number, visible: boolean};
  image: {id: string, name: string, order: number, visible: boolean};
  social: {id: string, name: string, order: number, visible: boolean};
  short: {id: string, name: string, order: number, visible: boolean};
  long: {id: string, name: string, order: number, visible: boolean};
  gallery: {id: string, name: string, order: number, visible: boolean};
  contact: {id: string, name: string, order: number, visible: boolean};
  boats: {id: string, name: string, order: number, visible: boolean};
}

const apiUrls = {
  auth: {
    login: `${apiPrefix}/login`,
    register: `${apiPrefix}/register`,
    social: `${apiPrefix}/social`,
    logout: `${apiPrefix}/logout`,
    passthrough: `${apiPrefix}/passthrough`,
    password: `${apiPrefix}/password`,
    otpLogin: `${apiPrefix}/otplogin`,
    user: {
      me: `${apiPrefix}/me`,
      edit: `${apiPrefix}/user/edit`,
      get: `${apiPrefix}/user/`,
      update: `${apiPrefix}/user`,
      delete: `${apiPrefix}/user/delete`,
      list: `${apiPrefix}/user/list`,
      bank: `${apiPrefix}/user/bank`,
      image: {
        get: `${apiPrefix}/user/image`,
        upload: '',
      },
      loginLink: `${apiPrefix}/user/login-link`,
      impersonate: `${apiPrefix}/user/impersonate`,
      contact: `${apiPrefix}/user/contact`,
    }
  },
  echo: {
    auth: `${websocketPrefix}/auth`,
    broadcast: `${websocketPrefix}/broadcast`,
    users: `${websocketPrefix}/users`,
    chat: `${websocketPrefix}/chat-guid`
  },
  chat: {
    get: `${apiPrefix}/chat/get`,
    list: `${apiPrefix}/chat/list`,
    messages: {
      delete: `${apiPrefix}/chat/messages/delete`
    }
  },
  mail: {

  },
  dashboard: {
    about: `${apiPrefix}/dashboard/about`,
    log: {
      list: `${apiPrefix}/dashboard/log/list`,
      get: `${apiPrefix}/dashboard/log/get`,
    },
    cache: {
      flush: `${apiPrefix}/dashboard/cache/flush`,
    },
    images: {
      fix: `${apiPrefix}/dashboard/images/fix`,
    },
    configs: {
      flush: `${apiPrefix}/dashboard/configs/flush`,
    },
  },
  boat: {
    get: `${apiPrefix}/boat/get`,
    fetch: `${apiPrefix}/boat/fetch`, // Same as get but requires auth (owner or admin)
    list: `${apiPrefix}/boat/list`,
    listByOwner: `${apiPrefix}/boat/owner/list`,
    add: `${apiPrefix}/boat/add`,
    recent: `${apiPrefix}/boat/recent`,
    edit: `${apiPrefix}/boat/edit`,
    update: `${apiPrefix}/boat/update`,
    delete: `${apiPrefix}/boat/delete`,
    image: {
      get: `${apiPrefix}/boat/image`,
      flip: `${apiPrefix}/boat/image/flip`,
      reorder: `${apiPrefix}/boat/image/reorder`,
      upload: `${apiPrefix}/boat/image/upload`,
      update: `${apiPrefix}/boat/image/update`,
    },
    faq: {
      list: `${apiPrefix}/boat/faq/list`
    },
    types: `${apiPrefix}/boat/types`,
    features: `${apiPrefix}/boat/features`,
    periods: `${apiPrefix}/boat/periods`,
    book: `${apiPrefix}/boat/book`,
    booking: `${apiPrefix}/boat/booking`,
    bookings: `${apiPrefix}/boat/bookings`,
    transfer: `${apiPrefix}/boat/transfer`,
  },
  marina: {
    get: `${apiPrefix}/marina/get`,
    list: `${apiPrefix}/marina/list`,
    edit: `${apiPrefix}/marina/edit`,
    update: `${apiPrefix}/marina/update`,
    image: {
      get: `${apiPrefix}/marina/image`,
      upload: '',
    }
  },
  content: {
    fetch: `${apiPrefix}/content/fetch`, // Same as get but requires auth (owner or admin)
    get: `${apiPrefix}/content/get`,
    public: `${apiPrefix}/content/public`,
    all: `${apiPrefix}/content/all`,
    list: `${apiPrefix}/content/list`,
    add: `${apiPrefix}/content/add`,
    edit: `${apiPrefix}/content/edit`,
    update: `${apiPrefix}/content/update`,
    delete: `${apiPrefix}/content/delete`,
    image: {
      get: `${apiPrefix}/content/image`,
      upload: `${apiPrefix}/content/image/upload`,
    },
    seo: {
      check: `${apiPrefix}/content/seo/check`,
    },
    category: {
      list: `${apiPrefix}/content/categories`,
    },
    route: {
      list: `${apiPrefix}/content/routes`,
    },
    tag: {
      list: `${apiPrefix}/content/tags`,
    },
  },
  options: {
    list: `${apiPrefix}/options/list`,
    update: `${apiPrefix}/options/update`,
    image: {
      upload: '',
    },
    file: {
      info: `${apiPrefix}/options/file/info`,
    }
  },
  currency: {
    list: `${apiPrefix}/currency/list`,
  },
  country: {
    get: `${apiPrefix}/country/get`,
    list: `${apiPrefix}/country/list`,
    edit: `${apiPrefix}/country/edit`,
  },
  cancellationPolicy: {
    list: `${apiPrefix}/cancellation-policy/list`,
  },
  paymentMethod: {
    list: `${apiPrefix}/payment-method/list`,
  },
  roles: {
    list: `${apiPrefix}/role/list`,
  },
  notification: {
    list: `${apiPrefix}/notification/list`,
    seen: `${apiPrefix}/notification/seen`
  },
  booking: {
    get: `${apiPrefix}/booking/get`,
    list: `${apiPrefix}/booking/list`,
    edit: `${apiPrefix}/booking/edit`,
    update: `${apiPrefix}/booking/update`,
    delete: `${apiPrefix}/booking/delete`,
    daily: `${apiPrefix}/booking/daily`,
    schedule: `${apiPrefix}/booking/schedule`,
    image: {
      upload: '',
    },
    statuses: `${apiPrefix}/booking/statuses`,
  },
  checkout: {
    stripe: {
      create: `${apiPrefix}/checkout/create`,
    }
  },
  api: {
    provider: {
      get: `${apiPrefix}/provider/get`,
      list: `${apiPrefix}/provider/list`,
    },
    endpoint: {
      get: `${apiPrefix}/endpoint/get`,
      list: `${apiPrefix}/endpoint/list`,
    }
  }
};

export {apiUrls};
