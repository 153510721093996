export const environment = {
  production: true,
  debug: false,
  title: 'Book.Boats',
  serverUrl: 'dev.book.boats',
  serverPort: null,
  useAnimation: true,
  stripePublishableKey: 'pk_test_0kGIiqKkBK9nT4U5CDfeecJz',
  googleMapApi: 'AIzaSyAQH5Ju7By8A1Ph2kBBqBwFda-C8onhUp0',
  googleClientIdOld: '814961634884-e2ppjik5m9vp7gvoi7ck6dtf8pl7lr19.apps.googleusercontent.com',
  googleClientId: '546279488217-0lavf5du3obpbq4cgaegrltghv51uvd8.apps.googleusercontent.com',
  recaptcha: {
    siteKeyV3: '6LekziMjAAAAABQqKZHJZMO-2onwDMYjvghJYkq8',
    siteKey: '6LdqiHcjAAAAAL-PMi2uE7Rfl4P4tVWM7MwQM91V'
  },
  fbAppId: '3338231116441021',
};
