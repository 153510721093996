import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'midTruncate'
})
export class MiddleTruncatePipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '…';
    value = value ?? '';
    return value.length > limit ?
      value.substr(0, Math.floor(limit / 2)) + trail + value.substr(value.length - Math.floor(limit / 2), value.length) :
      value;
  }
}
