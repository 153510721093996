import {Injectable} from '@angular/core';
import {FormGroup, ValidatorFn} from '@angular/forms';

/**
 * Providing a singleton service
 */
@Injectable({
  providedIn: 'root',
})
export class ValidatorService {

  constructor() {}

  conditional(field: string): ValidatorFn {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      const otherControl = formControl.parent.get(field);
      const error =
        formControl.value || otherControl?.value
          ? null
          : { error: `this field or ${field} is required` };
      if ((error && otherControl?.valid) || (!error && otherControl?.invalid)) {
        window.setTimeout(() => {
          otherControl.updateValueAndValidity({ emitEvent: false });
        });
      }
      return error;
    };
  }

  atLeastOneChecked(minRequired = 1): ValidatorFn {
    return formGroup => {
      let checked = 0;
      if (formGroup instanceof FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {
          const control = formGroup.controls[key];

          if (control.value === true) {
            checked++;
          }
        });

        if (checked < minRequired) {
          return {
            requireCheckboxToBeChecked: true,
          };
        }
      }

      return null;
    }
  }

  greaterThan(field: string): ValidatorFn {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      const otherControl = formControl.parent.get(field);
      if (!otherControl) {
        return null;
      }
      const isLessThan = Number(formControl.value) <= Number(otherControl.value);
      return isLessThan ? {'lessThan': {value: formControl.value}} : null;
    }
  }

  lessThan(field: string): ValidatorFn {
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      const otherControl = formControl.parent.get(field);
      if (!otherControl) {
        return null;
      }
      const isGreaterThan = Number(formControl.value) >= Number(otherControl.value);
      return isGreaterThan ? {'greaterThan': {value: formControl.value}} : null;
    }
  }
}
