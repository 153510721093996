import {Component, Input} from '@angular/core';

import {BACKEND_MENU, SITE_MENU} from './menu';
import {AbstractComponent} from '../../core/components/abstract.component';

export type MenuType = 'frontend' | 'backend';

/**
 * Side Menu Component
 */
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent extends AbstractComponent {

  private _type: MenuType = 'frontend';
  private _menu: any[] = [];

  @Input()
  get type(): MenuType {
    return this._type;
  }
  set type(val: MenuType) {
    this._type = val;
    this.menu = structuredClone(this._type === 'frontend' ? SITE_MENU : BACKEND_MENU);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override _userInfoChange(userInfo: any) {
    super._userInfoChange(userInfo);
    if (userInfo) {
      // this._adminItems.forEach(adminItem => {
      //   const itemIndex = this._menu.findIndex(item => {
      //     return item.link === adminItem.link;
      //   });
      //   if (this.roles.admin) {
      //     if (itemIndex === -1) {
      //       this._menu.splice(this._insertAt, 0, adminItem);
      //     }
      //   } else if (itemIndex >= 0) {
      //     this._menu.slice(itemIndex, 1);
      //   }
      // });
    }
  }

  get menu(): any[] {
    return this._menu;
  }

  set menu(val: any[]) {
    this._menu = val;
    this._menu.forEach(item => {
      item.active = this.global.currentRoute.endsWith(item.link);
      if (this.global.auth.userInfo && !this.global.auth.is('admin')) {
        ['boats', 'blog', 'pages', 'faq'].forEach(link => {
          if (item.link.endsWith(link) && this.global.auth.userInfo?.[link]) {
            item.badge = this.global.auth.userInfo[link].length;
          }
        });
      } else {
        item.badge = 0;
      }
    });
  }

  public switchRole(role: string) {
    this.global.auth.currentRole = role;
  }

}
