import {Injectable} from '@angular/core';

@Injectable()
export class ToolsService {

  slugify(input: string): string {
    if (input) {
      return input.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/--+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
    }
    return '';
  }

  ucFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Returns number rounded to N decimals
   * Note the rounding error due to imprecision in floating point arithmetic:
   * Math.round(1.005*100)/100 = 1
   * Math.round((1.005+Number.EPSILON)*100)/100 = 1.01
   * See: https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Math/round
   * @param num
   * @param dec
   */
  roundDec(num: number, dec = 2): number {
    if (dec > 0) {
      // to ensure things like 1.005 round correctly, use Number.EPSILON
      return Math.round((num + Number.EPSILON) * Math.pow(10, dec)) / Math.pow(10, dec);
    } else {
      return num;
    }
  }

  /**
   * 1 boat, 2 boats...
   * @param singular
   * @param count
   */
  plural(singular: string, count: number) {
    return `${count} ${singular}` + (count > 1 ? 's' : '');
  }

  hour(h: number): string {
    h = h >= 24 ? h - 24 : h;
    return (h <= 9 ? '0' : '') + `${h}:00`;
  }

  hoursDiff(startDate: number, endDate: number) {
    const msInHour = 1000 * 60 * 60;
    return Math.round(Math.abs(endDate - startDate) / msInHour);
  }

  addHours(date: Date, hours: number) {
    const dateCopy = new Date(date);
    dateCopy.setHours(dateCopy.getHours() + hours);
    return dateCopy;
  }

  dateInRange(date: Date, startDate: Date, endDate: Date) {
    return date >= startDate && date < endDate;
  }

  /**
   * Receives array of two numbers [7, 22], returns string '07:00 - 22:00'
   * @param range
   */
  timeRange(range: number[]): string {
    const hours = range.map((hour: number) => {
      return this.hour(hour);
    });
    return hours.join(' - ');
  }

  isToday(date: Date) {
    const today = new Date();
    return today.toDateString() === date.toDateString();
  }

  calcReadTime(body: string): string {
    const wordsPerMinute = 200; // Average case
    let result = `~0 min read`;
    let textLength = body.split(' ').length;
    if (textLength > 0) {
      let value = Math.ceil(textLength / wordsPerMinute);
      result = `~${value} min read`;
    }
    return result;
  }

  async copyToClipboard(content: any) {
    try {
      await navigator.clipboard.writeText(content);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  async copyPageUrl() {
    await this.copyToClipboard(window.location.href);
  }

  toBool(value: any): boolean {
    if (typeof value === 'string') {
      return value === 'true' || value === '1';
    } else if (typeof value === 'number') {
      return value > 0;
    }
    return !!value;
  }

  parseError(error: any) {
    const result = {
      message: error?.message ?? 'Unknown error',
      title: error?.statusText ?? '',
    }
    if (error?.status === 401 || error?.status === 422) {
      result.message = error?.message ?? 'Invalid credentials';
      if (error?.error?.message && error.error.message !== '') {
        result.title = error?.error?.message;
      }
    }
    return result;
  }

  isEmptyValue(value: any) {
    return value === undefined ||
      value === null ||
      (typeof value === 'string' && (value || '').trim().length === 0) ||
      (typeof value === 'number' && value === 0) ||
      (Array.isArray(value) && value.length === 0);
  }

  isEmpty(object: any) {
    return !object || (Object.keys(object).length === 0 && object.constructor === Object);
  }

  /**
   * Returns true if passed value is Object and is not empty
   * @param object
   */
  isObject(object: any) {
    return object && (Object.keys(object).length > 0 && object.constructor === Object);
  }

  /**
   * Joins not empty value, e.g.:
   * joinNotEmpty(['Hello', '', null, 'world', false], ' ') -> 'Hello world'
   * @param items
   * @param separator
   */
  joinNotEmpty(items: any[], separator?: string): string {
    return items.filter(Boolean).join(separator);
  }

  /**
   * Splits string by separator and ignores empty values
   * @param value
   * @param separator
   */
  splitNotEmpty(value: string, separator: string = ' '): string[] {
    return value.split(separator).filter(element => element);
  }

  download(data: any, fileName: string, mimeType = 'application/octet-stream') {
    const destroyClickedElement = function(event: any) {
      document.body.removeChild(event.target);
    }
    let fileAsBlob;
    if (data instanceof Blob) {
      fileAsBlob = data;
    } else {
      fileAsBlob = new Blob([ data ], { type: mimeType });
    }
    const downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    if (window.webkitURL != null) {
      // Chrome allows the link to be clicked without actually adding it to the DOM.
      downloadLink.href = window.webkitURL.createObjectURL(fileAsBlob);
    } else {
      // Firefox requires the link to be added to the DOM before it can be clicked.
      downloadLink.href = window.URL.createObjectURL(fileAsBlob);
      downloadLink.onclick = destroyClickedElement;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
    }
    downloadLink.click();
    if (!window.webkitURL) {
      document.body.removeChild(downloadLink);
    }
  }

  timestampWithoutTimezone(date: Date, subtract = true) {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return date.getTime() - (subtract ? userTimezoneOffset : Math.abs(userTimezoneOffset));
  }

  dateTimeToStr(date: Date | null = null, time: number | void) {
    date = date ?? new Date();
    date = new Date(this.timestampWithoutTimezone(date));
    const timeStr = typeof time !== 'undefined' ? (' ' + (time < 10 ? '0' : '') + time + ':00') : '';
    return date.toISOString().split('T')[0] + timeStr;
  }

  makeId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  flattenObj(obj: any) {
    let result: any = {};
    for (const i in obj) {
      if ((typeof obj[i]) === 'object' && !Array.isArray(obj[i])) {
        const temp = this.flattenObj(obj[i]);
        for (const j in temp) {
          result[i + '.' + j] = temp[j];
        }
      } else {
        result[i] = obj[i];
      }
    }
    return result;
  }

  deepValue(obj: any, path: string) {
    for (let i = 0, parts = path.split('.'), len = parts.length; i < len; i++) {
      obj = obj[parts[i]];
    }
    return obj;
  }

  /**
   * Cast value to integer, float or string. Return null if allowNull and value is null
   * @param type
   * @param value
   * @param allowNull
   * @param key
   */
  cast(type: 'int'|'float'|'string', value: any, allowNull: boolean = false, key = 'id') {
    if ((value === null || value === undefined) && allowNull) {
      return null;
    }
    if (Array.isArray(value) && key) {
      let result: any[] = [];
      value.forEach((item: any) => {
        result.push(this.cast(type, item?.[key], allowNull));
      });
      return result;
    }
    switch (type) {
      case 'int':
        return parseInt(value);
      case 'float':
        return parseFloat(value);
      default:
        return <string>value;
    }
  }

  /**
   * Returns true if resolution is less than 768px
   * @param item
   */
  isMobile(item: any) {
    let result = false;
    switch (item?.name) {
      case 'lt.768':
      case 'lt.992':
        result = item.value;
        break;
      case 'gt.768':
      case 'gt.1280':
        result = !item.value;
        break;
    }
    return result;
  }

  /**
   *
   * @param id
   * @param src
   * @param onload
   * @param parentElement
   */
  loadScript(id: string, src: string, onload: ()=>void, parentElement: HTMLHeadElement|null = null) {
    // get document if platform is only browser
    if (typeof document !== 'undefined' && !document.getElementById(id)) {
      let signInJS = document.createElement('script');
      signInJS.async = true;
      signInJS.src = src;
      signInJS.onload = onload;
      if (!parentElement) {
        parentElement = document.head;
      }
      parentElement.appendChild(signInJS);
    }
  }
}
