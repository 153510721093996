import {Component} from '@angular/core';

import {AbstractComponent} from '../../core/components/abstract.component';
import {ACCOUNT_MENU} from './menu';

/**
 * Nav (Top) Menu Component
 */
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent extends AbstractComponent {

  public show = false;
  private _menu = ACCOUNT_MENU;

  override ngOnInit(): void {
    super.ngOnInit();
  }

  get menu(): any[] {
    return this._menu;
  }

  userInfo() {
    this.global.navigate(['/account', 'info']);
  }

}
