import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';

import {environment} from '../../../environments/environment';
import {HttpService, Response} from './http.service';

export interface IListTab {
  name: string,
  icon: string,
}

// type TabKey = keyof IListTab;

@Injectable()
export abstract class AbstractService {

  protected useCache = false;
  protected name!: string;

  public list: any[] = [];
  public endpoint!: any;
  public listingUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public itemUpdated$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(protected http: HttpService) {}

  public url(url: string): string {
    return this.http.url(url);
  }

  protected badResponse() {
    return of({success: false, data: []});
  }

  private debug(func = '') {
    if (environment.debug) {
      console.log(`${func} `, this.constructor.name);
    }
  }

  public get mediaTypes(): any {
    return {
      image: `${this.name}.image`,
      file: `${this.name}.file`,
    }
  }

  public media = {
    images: (options: any[]) => {
      return Array.isArray(options) ? options.filter((option: any) => {
        return option?.name ? option.name.includes(this.mediaTypes.image) : false;
      }) : [];
    },
    files: (options: any[]) => {
      return Array.isArray(options) ? options.filter((option: any) => {
        return option?.name ? option?.name.startsWith(this.mediaTypes.file) : false;
      }) : [];
    },
  }

  get$(params: Params): Observable<Response> {
    if (params['id'] && params['fetch'] && this.endpoint?.fetch) {
      this.debug('Fetch');
      return this.http.list(this.endpoint.fetch, params);
    }
    if (this.endpoint?.get || params?.['endpoint']) {
      this.debug('Get');
      return this.http.list(this.endpoint?.get ?? params['endpoint'], params);
    }
    return this.badResponse();
  }

  list$(params: Params = {}): Observable<Response> {
    if (this.useCache && this.list && Array.isArray(this.list) && this.list.length) {
      return of({success: true, data: this.list, cached: true});
    }
    if (this.endpoint?.list || params?.['endpoint']) {
      return this.http.list(this.endpoint?.list ?? params['endpoint'], params);
    }
    return this.badResponse();
  }

  // Edit record
  edit$(params: Params = {}) {
    if (this.endpoint?.edit) {
      this.debug('Edit');
      return this.http.update(this.endpoint.edit, params, () => null);
    }
    return this.badResponse();
  }

  // Update single column
  update$(params: Params = {}) {
    if (this.endpoint?.update) {
      this.debug('Update');
      return this.http.update(this.endpoint.update, params, () => null);
    }
    return this.badResponse();
  }

  delete$(id: number, params: Params = {}) {
    if (this.endpoint?.delete) {
      this.debug('Delete');
      return this.http.delete(this.endpoint.delete, id, params);
    }
    return this.badResponse();
  }

  deleteImage$(id: number, params: Params = {}) {
    if (this.endpoint?.image) {
      this.debug('Delete image');
      return this.http.delete(this.endpoint.image.get, id, params);
    }
    return this.badResponse();
  }

  imageUrl(model: any, image: any = null, thumb: string | null = null) {
    image = image ?? this.media.images(model?.media)[0];
    let fileName = null;
    if (image && ((thumb && image?.[thumb]) || image?.fileName)) {
      fileName = thumb ? image[thumb] : image.fileName;
    }
    return fileName ?
      `https://${environment.serverUrl}/storage/${this.name}/image/${model.fileId ?? model.id}/${fileName}`:
      `/assets/img/logo/logo-lifebuoy.svg`;
  }

}
