import {ChangeDetectorRef, Component, ComponentRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {GlobalService} from '../../core/services/global.service';
import {AbstractComponent} from '../../core/components/abstract.component';
import {AppData} from '../../core/appdata';
import {environment} from '../../../environments/environment';

/**
 * Footer Component
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends AbstractComponent {

  @ViewChild('chatTmpl', { read: ViewContainerRef }) chatRef: ViewContainerRef | undefined;
  chat!: ComponentRef<any> | undefined;
  menuItems = {
    links: {
      expanded: false,
    },
    company: {
      expanded: false,
    },
    destinations: {
      expanded: false,
    },
    reviews: {
      expanded: false,
    },
  };
  whatsAppMsg = '';

  constructor(override route: ActivatedRoute,
              override router: Router,
              override global: GlobalService,
              override cd: ChangeDetectorRef,
              override appData: AppData) {
    super(route, router, global, cd, appData);
    this.global.chat.openChat$.subscribe((booking: any) => {
      if (booking?.chat?.guid) {
        this.global.chat.activeGuid = booking.chat.guid;
      }
      if (this.chat instanceof ComponentRef) {
        this.chat.setInput('display', true);
        if (booking && booking?.id !== this.chat?.instance?.booking?.id) {
          this.chat.setInput('booking', booking);
        }
      } else if (booking && this.chatRef) {
        const block = {
          ref: this.chatRef,
          loaded: false,
          onCreate: (chat: any) => {
            this.chat = chat.componentRef;
            chat.componentRef.setInput('popup', true);
            chat.componentRef.setInput('booking', booking);
            chat.componentRef.setInput('display', true);
            this.global.SidebarHide();
          },
          host: this,
          name: 'chat'
        }
        import('../../core/components/lazy/lazy-chat.module').then((importedFile) => {
          this.global.lazy.loadComponent(importedFile, block);
        });
      }
    });

    this.global.auth.userInfoChange$.subscribe((userInfo: any) => {
      if (userInfo === null) {
        // this.chat = undefined;
      }
    });

    this.global.boat.boatFetched$.subscribe((boat: any) => {
      if (boat && this.router.url.startsWith('/boat-hire')) {
        const path = this.router.createUrlTree([], {relativeTo: this.route}).toString();
        const url = `https://${environment.serverUrl}/${path}`;
        // Change WhatsApp link on boat page
        const msg = `Please can I have more information on ${boat?.name} - ${url}`;
        this.whatsAppMsg = `?text=${encodeURIComponent(msg)}`;
      } else {
        this.whatsAppMsg = '';
      }
    });
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Ensure that routes have been processed in order to check isBackend flag
    this.global.navigationEnded$.subscribe(value => {
      if (value) {
        this.whatsAppMsg = '';
        if (!this.isMobile && !this.global.isBackend && (!this.blogs || (Array.isArray(this.blogs) && !this.blogs.length))) {
          this.global.content.all$({type: 'blog', limit: 3}).subscribe(response => {
            if (response?.success === true && response?.data) {
              this.blogs = response.data;
            }
          });
        }
      }
    });
  }

  public scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public openChat() {
    this.global.chat.openChat$.next(null);
  }

}
