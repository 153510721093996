import {EventEmitter, Inject, Injectable} from '@angular/core';
import {BehaviorSubject, filter, skip, take} from 'rxjs';

import {ToolsService} from '../tools.service';
import {SocialUser} from './social-auth.service';

declare var google: any;

const defaultInitOptions = {
  oneTapEnabled: true,
};

/**
 * Providing a singleton service
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  private readonly initOptions?: any;
  private readonly PROVIDER_ID: string = 'GOOGLE';
  public readonly changeUser: EventEmitter<SocialUser>;
  private readonly _socialUser: BehaviorSubject<any|null> = new BehaviorSubject<any|null>(null);
  private readonly _accessToken: BehaviorSubject<any|null> = new BehaviorSubject<any|null>(null);
  private readonly _receivedAccessToken;
  private _tokenClient: any;

  constructor(@Inject(String) private clientId: string, @Inject({}) initOptions: any, private tools: ToolsService) {
    this.initOptions = initOptions;
    this.changeUser = new EventEmitter();
    this._receivedAccessToken = new EventEmitter();
    this.initOptions = { ...defaultInitOptions, ...this.initOptions };
    // emit changeUser events but skip initial value from behaviorSubject
    this._socialUser.pipe(skip(1)).subscribe(this.changeUser);
    // emit receivedAccessToken but skip initial value from behaviorSubject
    this._accessToken.pipe(skip(1)).subscribe(this._receivedAccessToken);
  }

  initialize(autoLogin: boolean) {
    return new Promise((resolve, reject) => {
      try {
        this.tools.loadScript(this.PROVIDER_ID, 'https://accounts.google.com/gsi/client', () => {
          google.accounts.id.initialize({
            client_id: this.clientId,
            auto_select: autoLogin,
            callback: (credential: any) => {
              const socialUser = this.createSocialUser(credential);
              this._socialUser.next(socialUser);
            },
            prompt_parent_id: this.initOptions?.prompt_parent_id,
            itp_support: this.initOptions.oneTapEnabled
          });
          if (this.initOptions.oneTapEnabled) {
            this._socialUser
            .pipe(filter((user) => user === null))
            .subscribe(() => google.accounts.id.prompt(console.debug));
          }
          if (this.initOptions.scopes) {
            const scope = this.initOptions.scopes instanceof Array
              ? this.initOptions.scopes.filter((s: string) => s).join(' ')
              : this.initOptions.scopes;
            this._tokenClient = google.accounts.oauth2.initTokenClient({
              client_id: this.clientId,
              scope,
              prompt: this.initOptions.prompt,
              callback: (tokenResponse: any) => {
                if (tokenResponse.error) {
                  this._accessToken.error({
                    code: tokenResponse.error,
                    description: tokenResponse.error_description,
                    uri: tokenResponse.error_uri,
                  });
                }
                else {
                  this._accessToken.next(tokenResponse.access_token);
                }
              },
            });
          }
          resolve(google);
        });
      }
      catch (err) {
        reject(err);
      }
    });
  }

  getLoginStatus() {
    return new Promise((resolve, reject) => {
      if (this._socialUser.value) {
        resolve(this._socialUser.value);
      }
      else {
        reject(`No user is currently logged in with ${this.PROVIDER_ID}`);
      }
    });
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      google.accounts.id.revoke(this._socialUser.value.id, (response: any) => {
        if (response.error)
          reject(response.error);
        else
          resolve(this._socialUser.value);
      });
    });
  }

  getAccessToken() {
    return new Promise((resolve, reject) => {
      if (!this._tokenClient) {
        if (this._socialUser.value) {
          reject('No token client was instantiated, you should specify some scopes.');
        }
        else {
          reject('You should be logged-in first.');
        }
      }
      else {
        this._tokenClient.requestAccessToken({
          hint: this._socialUser.value?.email,
        });
        this._receivedAccessToken.pipe(take(1)).subscribe(resolve);
      }
    });
  }

  revokeAccessToken() {
    return new Promise<void>((resolve, reject) => {
      if (!this._tokenClient) {
        reject('No token client was instantiated, you should specify some scopes.');
      }
      else if (!this._accessToken.value) {
        reject('No access token to revoke');
      }
      else {
        google.accounts.oauth2.revoke(this._accessToken.value, () => {
          this._accessToken.next(null);
          resolve();
        });
      }
    });
  }

  signIn() {
    return Promise.reject('You should not call this method directly for Google, use "<asl-google-signin-button>" wrapper ' +
      'or generate the button yourself with "google.accounts.id.renderButton()" ' +
      '(https://developers.google.com/identity/gsi/web/guides/display-button#javascript)');
  }

  async signOut() {
    google.accounts.id.disableAutoSelect();
    this._socialUser.next(null);
  }

  createSocialUser(idToken: any) {
    const user = new SocialUser();
    user.provider = this.PROVIDER_ID;
    user.idToken = idToken?.credential;
    const payload = this.decodeJwt(user.idToken ?? '');
    user.id = payload.sub;
    user.name = payload.name;
    user.email = payload.email;
    user.photoUrl = payload.picture;
    user.firstName = payload['given_name'];
    user.lastName = payload['family_name'];
    return user;
  }

  decodeJwt(idToken: string) {
    const base64Url = idToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window.atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
}
