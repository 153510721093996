import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const identity = this.auth.identity;
    if (environment.debug) {
      console.log('INTERCEPT', request, identity);
    }
    if (identity) {
      const cloned = request.clone({
        withCredentials: true,
        headers: request.headers
          .set('Authorization', `Bearer ${identity.token}`)
          .set('X-XSRF-TOKEN', identity.csrfToken ?? ''),
        params: request.params
          .set('currentRole', identity?.currentRole ?? '')
      });
      return next.handle(cloned);
    } else {
      return next.handle(request);
    }
  }
}
