import {MenuItem} from './menu.model';

export const SITE_MENU: MenuItem[] = [
  {
    id: 10,
    label: 'Home',
    link: '/',
    roles: {
      guest: true,
      owner: false
    },
    // subItems: [
    // {
    //     id: 2,
    //     label: 'Home v.1',
    //     link: '/home',
    //     parentId: 1
    // },
    // ]
  },
  {
    id: 20,
    label: 'How it works',
    link: '/how-it-works',
    roles: {
      guest: true,
      owner: false
    },
  },
  {
    id: 30,
    label: 'Personalised Search',
    link: '/page/let-us-find-your-perfect-yacht',
    roles: {
      guest: true,
      owner: false
    },
  },
  {
    id: 40,
    label: 'About Us',
    link: '/about-us',
    roles: {
      guest: true,
      owner: false
    },
  },
  {
    id: 50,
    label: 'Boat owners',
    link: '/boat-owners',
    // subItems: [
    //   {
    //     id: 161,
    //     label: 'Why list your boat',
    //     link: '/boat-owners',
    //     parentId: 16
    //   },
    //   {
    //     id: 162,
    //     label: 'Sign up',
    //     link: '#',
    //     modal: 'signup',
    //     type: 'owner',
    //     parentId: 16
    //   }
    // ],
    roles: {
      guest: true,
      owner: false,
      customer: false,
      agent: false,
      admin: false,
    },
  },
  // {
  //   id: 17,
  //   label: 'Agents',
  //   link: '/boat-agents',
  //   subItems: [
  //     {
  //       id: 171,
  //       label: 'Why use us',
  //       link: '/boat-agents',
  //       parentId: 17
  //     },
  //     {
  //       id: 172,
  //       label: 'Sign up',
  //       link: '#',
  //       modal: 'signup',
  //       type: 'agent',
  //       parentId: 17
  //     }
  //   ]
  // }
  // {
  //   id: 21,
  //   label: 'Pages',
  //   subItems: [
  //     {
  //         id: 22,
  //         label: 'About',
  //         link: '/pages/about',
  //         parentId: 21
  //     },
  //     {
  //       id: 23,
  //       label: 'Blog',
  //       subItems: [
  //         {
  //           id: 24,
  //           label: 'Blog Grid',
  //           link: '/pages/blog-grid',
  //           parentId: 23
  //         },
  //         {
  //           id: 25,
  //           label: 'Single Post',
  //           link: '/pages/single-blog',
  //           parentId: 23
  //         }
  //       ]
  //     },
  //     {
  //         id: 26,
  //         label: 'Contacts',
  //         link: '/pages/contacts',
  //         parentId: 21
  //     },
  //     {
  //       id: 27,
  //       label: 'Help Center',
  //       link: '/pages/help-center',
  //       parentId: 21
  //     },
  //     {
  //       id: 28,
  //       label: '404 Not Found',
  //       link: '/pages/404',
  //       parentId: 21
  //     },
  //   ]
  // },
];

/**
 * Drop-down menu in the header
 */
export const ACCOUNT_MENU: MenuItem[] = [
  {
    link: '/account/dashboard',
    icon: 'fi-dashboard',
    label: 'Dashboard',
    active: false,
    badge: 0,
    roles: {
      admin: true,
    },
  },
  // {
  //   link: '/account/users',
  //   icon: 'fi-users',
  //   title: 'Users',
  //   active: false,
  //   badge: 0,
  //   roles: {
  //     admin: true,
  //   },
  // },
  // {
  //   link: '/account/marinas',
  //   icon: 'fi-globe',
  //   title: 'Marinas',
  //   active: false,
  //   badge: 0,
  //   roles: {
  //     admin: true,
  //   },
  // },
  {
    link: '/account/boats',
    icon: 'fi-anchor',
    label: 'Boats',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      owner: true,
    },
  },
  {
    link: '/account/bookings',
    icon: 'fi-briefcase',
    label: 'Bookings',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      customer: true,
      owner: true,
      agent: true,
    },
  },
  {
    link: '/account/chats',
    icon: 'fi-chat-circle',
    label: 'Chats',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      customer: true,
      owner: true,
      agent: true,
    },
  },
  // {
  //   link: '/account/bookings/calendar',
  //   icon: 'fi-calendar',
  //   title: 'Calendar',
  //   active: false,
  //   badge: 0,
  //   roles: {
  //     customer: true,
  //     owner: true,
  //     agent: true,
  //   },
  // },
  {
    link: '/account/availability',
    icon: 'fi-clock',
    label: 'Boat availability',
    active: false,
    badge: 0,
    roles: {
      owner: true,
    },
  },
  {
    link: '/account/blog',
    icon: 'fi-edit',
    label: 'Blog',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      manager: true
    },
  },
  {
    link: '/account/pages',
    icon: 'fi-file',
    label: 'Pages',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      manager: true
    },
  },
  {
    link: '/account/faq',
    icon: 'fi-help',
    label: 'Faq',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      manager: true
    },
  },
  {
    link: '/auth/signout',
    icon: 'fi-logout',
    label: 'Sign Out',
    active: false,
    badge: 0,
    roles: {
      customer: true,
      owner: true,
      agent: true,
      admin: true,
      manager: true
    },
  },
];

/**
 * Side menu in user/admin account
 */
export const BACKEND_MENU: MenuItem[] = [
  {
    link: '/account/dashboard',
    icon: 'fi-dashboard',
    label: 'Dashboard',
    active: false,
    badge: 0,
    roles: {
      admin: true,
    },
  },
  {
    link: '/account/users',
    icon: 'fi-users',
    label: 'Users',
    active: false,
    badge: 0,
    roles: {
      admin: true,
    },
  },
  {
    link: '/account/providers',
    icon: 'fi-cloud-download',
    label: 'API',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      owner: true,
    },
  },
  {
    link: '/account/marinas',
    icon: 'fi-globe',
    label: 'Marinas',
    active: false,
    badge: 0,
    roles: {
      admin: true,
    },
  },
  {
    link: '/account/boats',
    icon: 'fi-anchor',
    label: 'Boats',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      owner: true
    },
  },
  {
    link: '/account/availability',
    icon: 'fi-clock',
    label: 'Boat availability',
    active: false,
    badge: 0,
    roles: {
      owner: true,
    },
  },
  {
    link: '/account/bookings',
    icon: 'fi-briefcase',
    label: 'Bookings',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      customer: true,
      owner: true,
      agent: true,
    },
  },
  {
    link: '/account/chats',
    icon: 'fi-chat-circle',
    label: 'Chats',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      customer: true,
      owner: true,
      agent: true,
    },
  },
  {
    link: '/account/bookings/calendar',
    icon: 'fi-calendar',
    label: 'Calendar',
    active: false,
    badge: 0,
    roles: {
      owner: true,
      agent: true,
    },
  },
  {
    link: '/account/blog',
    icon: 'fi-edit',
    label: 'Blog',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      manager: true
    },
  },
  {
    link: '/account/pages',
    icon: 'fi-file',
    label: 'Pages',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      manager: true
    },
  },
  {
    link: '/account/faq',
    icon: 'fi-help',
    label: 'Faq',
    active: false,
    badge: 0,
    roles: {
      admin: true,
      manager: true
    },
  },
  // {
  //   link: 'account/notifications',
  //   icon: 'fi-bell',
  //   title: 'Notifications',
  //   active: false,
  //   badge: 0,
  // },
  // {
  //   link: '/account/wishlist',
  //   icon: 'fi-heart',
  //   title: 'Wishlist',
  //   active: false,
  //   badge: 0,
  //   roles: {customer: true},
  // },
  // {
  //   link: '/account/reviews',
  //   icon: 'fi-star',
  //   title: 'Reviews',
  //   active: false,
  //   badge: 0,
  //   roles: {owner: true},
  // },
  {
    link: '/account/settings',
    icon: 'fi-settings',
    label: 'Settings',
    active: false,
    badge: 0,
    roles: {
      admin: true,
    },
  },
  {
    link: '/account/bank',
    icon: 'fi-accounting',
    label: 'Bank details',
    active: false,
    badge: 0,
    roles: {
      owner: true,
    },
  },
  {
    link: '/account/info',
    icon: 'fi-user',
    label: 'Personal Info',
    active: false,
    badge: 0,
    roles: {
      customer: true,
      agent: true,
      owner: true,
      admin: true,
      manager: true,
    },
  },
  // {
  //   link: '/account/security',
  //   icon: 'fi-lock',
  //   title: 'Password & Security',
  //   active: false,
  //   badge: 0,
  //   roles: {
  //     customer: true,
  //     agent: true,
  //     owner: true,
  //     admin: true,
  //     manager: true,
  //   },
  // },
  // {
  //   link: '/pages/help-center',
  //   icon: 'fi-help',
  //   title: 'Help',
  //   active: false,
  //   badge: 0,
  //   roles: {
  //     customer: true,
  //     agent: true,
  //     owner: true,
  //     admin: true,
  //     manager: true,
  //   },
  // },
  {
    link: '/auth/signout',
    icon: 'fi-logout',
    label: 'Sign Out',
    active: false,
    badge: 0,
    roles: {
      customer: true,
      agent: true,
      owner: true,
      admin: true,
      manager: true,
    },
  },
];
