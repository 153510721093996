import {
  AfterViewInit,
  Directive,
  HostListener,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UntypedFormGroup} from '@angular/forms';
import {SubscriptionLike} from 'rxjs';

import {GlobalService} from '../services/global.service';
import {constant} from '../config';
import {AutoUnsub} from '../decorators/auto-unsub';
import {RoleKey, Roles} from '../services/auth.service';
import {AppData} from '../appdata';

export type SearchTerms = {
  marina: any;
  date: any;
  from: number,
  to: number,
  type: any;
  // length: number;
  minLength: number;
  maxLength: number;
  capacity: number;
  // cabins: number;
  // berths: number;
  verified: any;
  instant: any;
}

export type Subscriptions = {
  [key: string]: SubscriptionLike;
}

export class FetchStatus {
  private _fetching = false; // True when fetching is in progress
  private _fetched = false; // True when fetching is finished no matter if it was successful or not

  get fetching(): boolean {
    return this._fetching;
  }

  set fetching(value: boolean) {
    this._fetching = value;
    if (value) {
      this._fetched = false;
    }
  }

  get fetched(): boolean {
    return this._fetched;
  }

  set fetched(value: boolean) {
    this._fetched = value;
    if (value) {
      this.fetching = false;
    }
  }
}

@Directive()
@AutoUnsub()
export abstract class AbstractComponent implements OnInit, AfterViewInit, OnDestroy {

  protected _userInfo$!: SubscriptionLike;
  protected _mediaEvent$!: SubscriptionLike;
  protected _messageReceived$!: SubscriptionLike;
  protected _optionListingLoaded$!: SubscriptionLike;
  private _fetchCounter = 0; // _fetchData() counter
  private _fetchCounterTimer!: number;
  private _fetchCounterTimeout = 10; // After X seconds clear _fetchCounter;
  private _prevUserInfo!: any;
  protected _boats: any[] = [];
  protected isPreview = false;
  protected firstRun = true;
  protected worker!: Worker;
  protected workerPath!: string;
  protected stylesheets: string[] = [];
  protected componentName: string = 'abstract';

  blogs: any[] = [];
  features: any[] = [];
  periods: any[] = [];
  year: number = (new Date()).getFullYear();
  constant = constant;
  assets = constant.IMAGE_PATH;
  userInfoRequired: boolean = false;
  mainForm!: UntypedFormGroup;
  breadCrumbItems!: Array<{}>;
  routeParams!: Params;
  isMobile: boolean = false;
  fetchStatus: FetchStatus  = new FetchStatus();
  boatsStatus: FetchStatus  = new FetchStatus();
  viewInitialized = false;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public global: GlobalService,
              public cd: ChangeDetectorRef,
              public appData: AppData) {
    this._mediaEvent$ = this.global.mediaEvent$.subscribe(item => {
      this.isMobile = this.global.tools.isMobile(item);
      this.layoutUpdated();
    });

    this._messageReceived$ = this.global.buffer.messageReceived$.subscribe((message: any) => {
      this.onNotification(message);
    });

    this.global.closeMobileMenu();

    this._init();
  }

  /**
   * Function _init() called once from constructor
   * Avoids calling super.constructor and inject all services in descendant classes
   * Note: Because of Initialization Order the derived class fields values that have been initialized at the top of class definition
   * Note: will not be accessible in this function. See: https://www.typescriptlang.org/docs/handbook/2/classes.html#extends-clauses
   * Note: So it's better to initialize field values in this function, not in the class definition
   * Note: Another solution is to make getters/setters instead of class fields.
   * @protected
   */
  protected _init() {}

  protected _initWorker() {
    if (typeof Worker !== 'undefined') {
      if (this.workerPath) {
        this.worker = new Worker(new URL(this.workerPath, import.meta.url));
        this.worker.onmessage = ({ data }) => {
          console.log(`page got message: ${data}`);
        };
        this.worker.postMessage('hello');
      }
    } else {
      // You should add a fallback so that your program still executes correctly.
      console.log('Web workers are not supported in this environment.');
    }
  }

  /**
   * Data Fetch called from ngOnInit()
   * Can be used to fetch multiple different objects, e.g.: boat types, marinas, features, etc
   * @protected
   */
  protected _fetchData() {
    this.fetchCounter++;
  }

  /**
   * Fetch single object, e.g. boat or content
   * Called from:
   * _initForm() - inside route.params subscription
   * _submit() - inside service.edit$ subscription, on successful response and this.isEdit is true
   * deleteImage() - inside service.deleteImage$ subscription, on successful response
   * @param data
   * @protected
   */
  protected _fetch(data?: any) { }

  protected _userInfoChange(userInfo: any) { }

  protected layoutUpdated() {
    if (this.viewInitialized) {
      this.cd.detectChanges();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resize(event);
  }

  protected onNotification(message: any) { }

  // get monthsCount(): number {
  //   return window.innerWidth > 736 ? 2 : 1;
  // }

  resize(event: any) {}

  get boats(): any[] {
    return this._boats;
  }

  set boats(value: any[]) {
    this._boats = value;
    this.cd.detectChanges();
  }

  get types(): any[] {
    return this.global.boat.types ?? [];
  }

  get roles(): Roles {
    return this.global.auth.roles;
  }

  get marinas(): any[] {
    return this.global.marina.list ?? [];
  }

  get fetchCounter(): number {
    return this._fetchCounter;
  }

  set fetchCounter(value: number) {
    this._fetchCounter = value;
    if (this._fetchCounterTimer) {
      window.clearTimeout(this._fetchCounterTimer);
    }
    this._fetchCounterTimer = window.setTimeout(() => {
      this._fetchCounter = 0;
    }, this._fetchCounterTimeout * 1000);
  }

  protected filterBoats(boats: any[], callback: (boat: any) => boolean): any[] {
    return boats.filter((boat: any) => {
      return callback(boat);
    });
  }

  protected loadBoats(params: Params = {}, success: (response: any) => void = ()=>{}) {
    /**
     * If parameters with the same name but a different value are passed, they will be overridden.
     */
    const requestParams = Object.assign({
      ownerId: this.global.auth.identity?.id,
      status: 'active',
      perPage: 0, // Zero means no pagination,
    }, params);
    this.boatsStatus.fetching = true;
    this.global.boat.listByOwner$(requestParams).subscribe(response => {
      this.boatsStatus.fetching = false;
      if (response?.success === true && response?.data && Array.isArray(response?.data?.list?.items)) {
        this.boats = response.data.list.items;
        if (success && typeof success === 'function') {
          success(response);
        }
      }
    });
  }

  searchSize(value: number, boundary: boolean): void {
    if (boundary) {
      this.global.boat.searchTerms.minLength = value;
    } else {
      this.global.boat.searchTerms.maxLength = value;
    }
  }

  changeHour(value: number, boundary: boolean): void {
    this.global.boat.changeHour(value, boundary);
  }

  search() {
    if (this.global.boat.searchTerms.marina !== null && this.global.boat.searchTerms.date !== null) {
      this.router.navigate(['/search'], {queryParams: this.queryObject()});
    }
  }

  queryObject(): any {
    let obj: any = {};
    for (const [key, value] of Object.entries(this.global.boat.searchTerms)) {
      obj[key] = typeof value === 'object' ? this.global.tools.slugify(value?.name) : value;
    }
    return obj;
  }

  /**
   * Returns form
   */
  get controls() {
    return this.mainForm.controls;
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  able(roles: {
    guest?:boolean,
    customer?: boolean,
    agent?: boolean,
    owner?: boolean,
    admin?: boolean,
    manager?: boolean,
  }): boolean {
    const currentRole = this.global.auth.currentRole;
    const result = Object.keys(this.roles).find(role => {
      return this.roles[role as RoleKey] &&
        (roles.hasOwnProperty(role) && roles[role as RoleKey] === true) &&
        role === (currentRole as RoleKey)
    });
    return !!result;
  }

  downloadFile(option: any) {
    this.global.option.getFileInfo$({
      option: option
    }).subscribe((response: any) => {
      if (response?.success === true && response?.data?.link) {
        this.global.api.getFileData$(response.data.link).subscribe((response: any) => {
          if (response instanceof Blob) {
            this.global.tools.download(response, option?.value ?? option?.fileName);
          }
        });
      }
    })
  }

  /**
   * Methods call order inside userInfoChange$ subscription:
   * _userInfoChange(userInfo);
   * _fetchData();
   * onResize();
   */
  ngOnInit(): void {
    this.viewInitialized = true;
    this.layoutUpdated();
    // Lazy load stylesheets
    this.stylesheets?.forEach(name => {
      this.global.lazy.loadStylesheet(`/${name}.css`, `${name}-css`);
    });

    this.routeParams = this.route.snapshot.params;

    this._initWorker();

    this._userInfo$ = this.global.auth.userInfoChange$.subscribe({
      next: (userInfo: any) => {
        this._userInfoChange(userInfo);
        if (!this.userInfoRequired || (this.userInfoRequired && userInfo !== null)) {
          if (this._prevUserInfo !== userInfo) {
            this._prevUserInfo = userInfo;
            // Data Get Function
            this._fetchData();
          }
          this.onResize(new Event('resize'));
        }
      }
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {}
}
