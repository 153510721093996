import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {constant} from '../../core/config';
import {GlobalService} from '../../core/services/global.service';


/**
 * Signup Component
 */
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  passTextType!: boolean;
  fieldTextType!: boolean;
  //  Validation form
  validationform!: UntypedFormGroup;
  submit!: boolean;
  assets = constant.IMAGE_PATH;
  title = '';
  features: string[] = [];
  private _modalType = {
    mode: 'signup',
    userRole: 'customer'
  }
  private _role!: string;

  constructor(public global: GlobalService,
              public activeModal: NgbActiveModal) {
  }

  get modalType(): any {
    return this._modalType;
  }

  get role(): string {
    return this._role;
  }

  set role(value: string) {
    this._role = value;
    if (value) {
      this.title = value;
      this._modalType.userRole = value;
      switch (value) {
        case 'owner':
          this.features = [
            'Add and promote your boats',
            'Manage your bookings'
          ];
          break;
        case 'agent':
          this.features = [
            'Manage your bookings'
          ];
          break;
      }
      this.passEntry.emit(this.modalType);
    }
  }

  signin() {
    this.activeModal.close('Ok');
    this.global.auth.signin();
  }

  ngOnInit(): void {}

  /**
   * Password Hide/Show
   */
   togglePassFieldTextType() {
    this.passTextType = !this.passTextType;
  }

}
